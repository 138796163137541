import React from "react";
import twitter from "../images/twitter.svg";
import facebook from "../images/facebook.svg";
import instagram from "../images/instagram.svg";
import youtube from "../images/youtube.svg";
import * as containerStyles from "./footer.module.css"


const links = [
  {
    href: "https://twitter.com/metrotram_qa",
    src: twitter,
    alt: "twitter",
  },
  {
    href: "https://www.facebook.com/Doha-Metro-Lusail-Tram-323139485195392",
    src: facebook,
    alt: "facebook",
  },
  {
    href: "https://www.instagram.com/metrotram_qa/",
    src: instagram,
    alt: "instagram",
  },
  {
    href: "https://www.youtube.com/channel/UCgJIYqdThR2wn1_F-xuIA7w",
    src: youtube,
    alt: "youtube",
  },
];

function Footer() {
  // Import result is the URL of your image
  return (
    <>
      <footer
        className={containerStyles.footer}
      >

        <img src="/media/img/tram_metro_logo.svg" alt="tram metro logo" className="w-32 pb-2 opacity-60" />
        <span
          className={containerStyles.socialContainer}
        >
          {links.map((link) => (
            <a className={containerStyles.socialIcons} href={link.href} key={link.alt}>
              <img src={link.src} alt={link.alt} width="60" height="60" className={containerStyles.sImg} />
            </a>
          ))}
        </span>
      </footer>
    </>

  );
}
export default Footer;
