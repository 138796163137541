import React from "react"
// import logo from "../images/explore_stations_header.svg";
import * as containerStyles from "./header.module.css"

let logoSrc = "/media/img/explore_stations_header.svg"
function Header({ imgsrc, imgClassName, eventBanner }) {
  // Import result is the URL of your image
  return (
    <>
      {eventBanner ? 
      <header className={containerStyles.header}>
      <a href="/events/" className={containerStyles.logocontainerevent}>
        <img width="500" height="67" src={eventBanner} className={containerStyles.img} alt="Qatar Rail Logo" />
      </a>
      </header>
      : null}
      <header className={containerStyles.header}>
        <div className={containerStyles.logocontainer}>
          <img width="500" height="67" src={imgsrc || logoSrc} className={imgClassName || containerStyles.logo} alt="Qatar Rail Logo" />
        </div>
      </header>
      {/* <div className={containerStyles.header}>
      <a href="/events">
        <img
          width="500"
          height="67"
          src={'/media/events/events_logo.png'}
          className="w-full"
          alt="Qatar Rail Logo"
          
        />
      </a>
      </div> */}
    </>

  )
}
export default Header