import * as React from "react";
import { Helmet } from "react-helmet";
import Header from "../componenets/header";
import Footer from "../componenets/footer";
// import phone from "../images/phone.png";
import * as containerStyles from "./layout.module.css";

function Chat() {
  return (
      // <div
      //   className={containerStyles.chat}
      // >
      // <div className="text-center p-1 max-w-sm mx-auto bg-red-800 hover:bg-red-700 rounded-l shadow-md flex items-center text-sm md:text-base">
      //   <a href="tel:105">
      //     <div><img src="./media/img/call_hotline.svg" alt="phone" className="w-6" /> 105</div>
      //   </a>
      // </div>
      // </div>
            <div
        className={containerStyles.chat}
      >
        <a href="tel:105" className="">
          <img src="/media/img/call_hotline.svg" alt="phone" className="h-20 md:h-32 border-t border-r border-l border-white" />
        </a>
      </div>
  );
}

const Layout = ({ pageTitle, children, theme ,disableChat, imgClassName, disableHeader, imgsrc, eventBanner}) => {
  return (
    <div className={containerStyles.layout}>
      <Helmet htmlAttributes={{ lang: "en" }}>
        <meta charset="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta http-equiv="X-UA-Compatible" content="ie=edge" />
        <meta name="description" content="Station Information - Qatar Rail" />
        <title>{pageTitle}</title>
      </Helmet>
      {disableHeader? null: <Header imgsrc={imgsrc} imgClassName={imgClassName} eventBanner={eventBanner} />}
      
      {/* <div className={containerStyles.line} /> */}
      <section id="app" className={` ${containerStyles.main} ${theme || containerStyles.mainTheme}`}>
        {children}
      </section>
      {/* <div>
        <br />
      </div> */}
      <Footer />
      {disableChat? null: <Chat />}
    </div>
  );
};
export default Layout;
